
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
@Component
@KeepAlive
export default class QuestionSurvey extends Vue {
  private loading = false
  private info = {
    parkName: '',
    questionnaireTitle: '',
    questionnaireType: '2',
    isPublished: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData = []

  get projectList () {
    return this.$store.state.projectList || []
  }

  created (): void {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.questionSurvey.selectQuestionnaireByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onAdd () {
    this.$router.push('/questionsurvey/add')
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'QuestionsurveyUpdate',
      params: { id }
    })
  }

  onPub (id: string, isPublished: string) {
    this.$axios.post(this.$apis.questionSurvey.publishQuestionnaireById, {
      id: id
    }).then(() => {
      this.loadData()
      this.$message({
        message: isPublished === '0' ? '上架成功' : '下架成功',
        type: 'success'
      })
    })
  }

  onDetail (row: { id: string; parkName: string }) {
    if (this.info.questionnaireType === '2') {
      this.$router.push(`/questionsurvey/cDetail${row.id}?parkName=` + row.parkName)
    } else {
      this.$router.push({
        name: 'QuestionsurveyDetail',
        params: { id: row.id }
      })
    }
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.questionSurvey.deleteQuestionnaire, {
        id: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.loadData()
      })
    })
  }
}
